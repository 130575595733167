import React from 'react';

import { Box } from '@mui/material';

import ChatDrawer from '../../components/ChatDrawer';
import DashboardContent from '../../components/DashboardContent';
import DashboardTopBar from '../../components/DashboardTopBar';
import EventDrawer from '../../components/EventDrawer';
import HomeBottomBar from '../../components/HomeBottomBar';
import PoiPreview from '../../components/PoiPreview';
import OrientationOverlay from '../../components/OrientationOverlay';
import VideoBubbles from '../../components/VideoBubbles';
import LeaveRegionDialog from '../../components/LeaveRegionDialog';
import TeleportToRoomDialog from '../../components/TeleportToRoomDialog';
import MillicastShareScreenModal from '../../components/MillicastSharing/MillicastScreen';
import MillicastShareVideoModal from '../../components/MillicastSharing/MillicastVideo';
import AdminSettings from '../../components/AdminSettings';
import DolbyCameraDialog from '../../components/DolbyCameraDialog';

import { IHomePageView } from './interfaces';

import { useStyles } from './styles';

const HomePageView = ({
  showOverlay,
  showControls,
  openMillicastShareScreenModal,
  openMillicastShareVideoModal,
  openAdminSettingsModal,
}: IHomePageView) => {
  const classes = useStyles(showOverlay);

  return (
    <>
      <Box id="home" sx={classes.homePage}>
        {showControls && <DashboardTopBar />}
        <VideoBubbles />
        <EventDrawer />
        <ChatDrawer />
        <Box sx={classes.root}>
          <DashboardContent />
          <PoiPreview />
          <LeaveRegionDialog />
          <TeleportToRoomDialog />
          <DolbyCameraDialog />
          {openAdminSettingsModal && <AdminSettings />}
          {openMillicastShareScreenModal && <MillicastShareScreenModal />}
          {openMillicastShareVideoModal && <MillicastShareVideoModal />}
        </Box>
        {showControls && <HomeBottomBar />}
      </Box>
      {showOverlay && <OrientationOverlay />}
    </>
  );
};

export default HomePageView;
