import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAsyncEffect from 'use-async-effect';
import useSound from 'use-sound';

import { camelCase } from 'lodash';

import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { useMytaverseEvent } from '../../../providers';
import { useNotification } from '../../../../../components/Notification';
import { DolbyService, useConference } from '../Dolby';
import { useHandleUEConnection } from './ueWsConnection';

import { setSessionStorageValue } from '../../../../../helpers/sessionStorage';

import EventsService from '../../../../../services/EventsService';

import FollowMeSound from '../../../../../public/sounds/followMe.mp3';

import {
  IParticipantPositionMessage,
  IWebSocketConnectionInfo,
  LastWebsocketJsonMessageType,
  SpatialType,
  WebsocketAction,
  WebsocketMessage,
} from '../../../../../interfaces/webSocketConnectionInfo';
import {
  SendToBriefcasePropsTypeFull,
  StartStreamVideoRequestType,
} from '../interfaces';
import { IVideoPosition } from '../Pureweb/interfaces';
import { SessionStorage } from '../../../../../constants/storage';
import { IFollowerData } from '../../../../../interfaces/followers';
import { UnknownObjectType } from '../../../../../interfaces';

interface StartStreamProps {
  region: string;
  roomId: string;
  screenName?: string;
}

interface HandleJoinedToRoomProps {
  roomId: string | null;
  participantId: string;
  gameSessionId: string | null;
  region?: string | null;
}

interface HandleLeftRoomProps {
  participantId: string;
}

const {
  ConnectionInfo,
  JoinedToRoom,
  LeftRoom,
  JoinedToEvent,
  LeftEvent,
  JoinToRegion,
  JoinedToRegion,
  LeaveRegion,
  LeftRegion,
  ChangeRoomScale,
  BrowserUrlRequest,
  MuteParticipant,
  ShowParticipantInfo,
  SendToBriefcase,
  ChangeParticipantProfile,
  MessageToWebapp,
  Ue5WsConnected,
  Ue5WsDisconnected,
  FirstPersonViewRegion,
  FreePersonViewRegion,
  StartShareScreenRequest,
  StartShareVideoRequest,
  StopShareScreenRequest,
  StopShareVideoRequest,
  MuteParticipantState,
  MuteConference,
  ParticipantPosition,
  StopDolbyScreenSharing,
  ParticipantCameraPosition,
  FollowPlayer,
  InviteFollowingByAdmin,
  StopFollowingByAdmin,
  CancelInviteFollowingByAdmin,
  AcceptFollowing,
  DisengageFollowing,
  StopFollowing,
  OpenTeleportModal,
  CloseTeleportModal,
} = WebsocketAction;

const { ShowControls, HideControls } = WebsocketMessage;

export const useGetWebsocketMsg = () => {
  const prevWebsocketMsgStrRef = useRef('');

  const getWebsocketMsg = (
    websocketMsg: LastWebsocketJsonMessageType,
  ): LastWebsocketJsonMessageType => {
    const websocketMsgStr = JSON.stringify(websocketMsg);

    if (prevWebsocketMsgStrRef.current === websocketMsgStr) {
      return null;
    }

    prevWebsocketMsgStrRef.current = websocketMsgStr;

    return websocketMsg;
  };

  return getWebsocketMsg;
};

// TODO should be split
export const useHandleWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const loadingScreenStartTimeRef = useRef<number | null>();

  const { setWebsocketConnectionInfo } = useMytaverse();

  const {
    updateParticipant,
    setParticipantState,
    pointsOfInterest,
    setPoiPreviewSrc,
    userFiles,
    setUserFiles,
    setShowControls,
    setShareMediaParams,
    trackAnalytics,
    setOpenShareScreenModal,
    setOpenShareVideoModal,
    stopShareVideo,
    shareScreenPublishers,
    handleStopScreenStream,
    shareVideoPublishers,
    currentParticipant,
    setMuted,
    muted,
    setUnmutedParticipantsIds,
    setOpenCameraPublisherDialog,
    nearbyParticipants,
    setNearbyParticipants,
    setRoomDolbySpatialAudioScale,
  } = useMytaverseEvent();
  const {
    setPreviewingParticipant,
    setDisabledPersonView,
    setParticipantPosition,
    setScreenMediaStreams,
    screenMediaStreams,
  } = useConference();
  const { showNotification, getSuccessNotification } = useNotification();
  const { t: translate } = useTranslation('common');
  const { handleUe5WsConnected, handleUe5WsDisconnected } =
    useHandleUEConnection();

  useAsyncEffect(async () => {
    if (
      currentParticipant &&
      !currentParticipant.roomId &&
      !loadingScreenStartTimeRef.current
    ) {
      await trackAnalytics('LOADING_SCREEN_START', {});
      loadingScreenStartTimeRef.current = new Date().getTime();
    }
  }, [trackAnalytics, currentParticipant]);

  useAsyncEffect(async () => {
    if (
      currentParticipant &&
      currentParticipant.roomId &&
      loadingScreenStartTimeRef.current
    ) {
      await trackAnalytics('LOADING_SCREEN_FINISH', {
        duration: new Date().getTime() - loadingScreenStartTimeRef.current,
      });
      loadingScreenStartTimeRef.current = null;
    }
  }, [currentParticipant, trackAnalytics]);

  const handleJoinedToRoom = useCallback(
    ({ roomId, participantId, gameSessionId }: HandleJoinedToRoomProps) => {
      if (
        !roomId ||
        !participantId ||
        // TODO deprecated
        (currentParticipant &&
          currentParticipant.id === participantId &&
          currentParticipant.isNewParticipantPositionFlow)
      ) {
        return;
      }

      // eslint-disable-next-line no-console
      console.log(`Participant ${participantId} joined to room ${roomId}`);

      setParticipantState(participantId, {
        roomId,
        region: null,
        regions: [],
        gameSessionId,
      });
    },
    [setParticipantState, currentParticipant],
  );

  const handleLeftRoom = useCallback(
    ({ participantId }: HandleLeftRoomProps) => {
      if (!participantId) {
        return;
      }

      // eslint-disable-next-line no-console
      console.log(`Participant ${participantId} left to room`);

      setParticipantState(participantId, {
        roomId: null,
        region: null,
        regions: [],
        gameSessionId: null,
      });
    },
    [setParticipantState],
  );

  const handleScreenVideoRequest = useCallback(
    ({ roomId, region, screenName }: StartStreamProps) => {
      handleStartStreamVideoRequest({ roomId, region, screenName });
      setOpenShareScreenModal(true);
    },
    [setOpenShareScreenModal],
  );

  const handleShareVideoRequest = useCallback(
    ({ roomId, region, screenName }: StartStreamProps) => {
      if (shareVideoPublishers) {
        if (!roomId || !screenName) {
          return;
        }
        const shareMediaParams = {
          roomId,
          region,
          screenName,
        };
        setShareMediaParams(shareMediaParams);
        setOpenCameraPublisherDialog(true);

        return;
      }
      handleStartStreamVideoRequest({ roomId, region, screenName });
      setOpenShareVideoModal(true);
    },
    [setOpenShareVideoModal, shareVideoPublishers],
  );

  const handleStopScreenSharing = useCallback(
    (screenName: string) => {
      const stream = shareScreenPublishers?.find(
        (s) =>
          s.streamName.split('-')[2].toLowerCase() === screenName.toLowerCase(),
      );

      if (stream) {
        handleStopScreenStream(stream.options.mediaStream.id);
      }
    },
    [shareScreenPublishers, handleStopScreenStream],
  );

  const handleStopDolbySharing = useCallback(
    (mediaStreamId: string) => {
      setScreenMediaStreams((prev) =>
        prev.filter((s) => s.stream.id !== mediaStreamId),
      );
    },
    [setScreenMediaStreams, screenMediaStreams],
  );

  const handleShowParticipantInfo = useCallback(
    async (participantId = '') => {
      if (!participantId) {
        return;
      }

      const participantProfile = await EventsService.getParticipantProfile(
        participantId,
      );
      if (participantProfile) {
        setPreviewingParticipant(participantProfile);
      }
    },
    [setPreviewingParticipant],
  );

  const handleSendToBriefcase = useCallback(
    ({
      fileName,
      downloadUrl = '',
      mediaType,
    }: SendToBriefcasePropsTypeFull) => {
      if (!fileName || !pointsOfInterest) {
        return;
      }

      setPoiPreviewSrc({
        downloadUrl,
        fileName,
        mediaType,
      });

      const existingFile = userFiles.some(
        ({ displayName }) => displayName === fileName,
      );

      if (existingFile) {
        return;
      }

      const file = pointsOfInterest.find(
        ({ displayName }) => displayName === fileName,
      );

      if (file) {
        setUserFiles((prev) => Array.from(new Set([file, ...prev])));
      }
    },
    [pointsOfInterest, setPoiPreviewSrc, setUserFiles, userFiles],
  );

  const trackPoiClick = useCallback(
    (fileName: string) => {
      if (!fileName || !pointsOfInterest) {
        return;
      }

      const poi = pointsOfInterest.find(
        ({ displayName }) => displayName === fileName,
      );

      if (poi) {
        const { id, asset, displayImage, displayName, mediaType, room } = poi;

        trackAnalytics('POI_CLICK', {
          poi: { id, asset, displayImage, displayName, mediaType, room },
        }).then(() => {});
      }
    },
    [pointsOfInterest, trackAnalytics],
  );

  const handleParticipantPositionChanged = useCallback(
    ({
      timestamp,
      gameSessionId,
      eventId,
      roomId,
      x,
      y,
      z,
      r,
      regionName,
      regionSpatialType,
    }: IParticipantPositionMessage) => {
      if (!currentParticipant) {
        return;
      }

      const currentRegionName = currentParticipant.region
        ? currentParticipant.region.region
        : '';

      if (
        !currentParticipant.roomId ||
        currentParticipant.roomId !== roomId ||
        currentRegionName !== regionName
      ) {
        setParticipantState(currentParticipant.id, {
          eventId,
          roomId,
          region: regionName
            ? {
                timestamp: Date.now(),
                region: regionName,
                state: 'joined',
                regionSpatialType:
                  regionSpatialType && regionSpatialType.length !== 0
                    ? regionSpatialType
                    : SpatialType.SpatialAudio,
              }
            : null,
          regions: [],
          gameSessionId,
          isNewParticipantPositionFlow: true,
        });
      }

      setParticipantPosition(currentParticipant.id, timestamp, {
        x: Math.trunc(x),
        y: Math.trunc(y),
        z: Math.trunc(z),
        r: Math.trunc(r),
      });
      setSessionStorageValue(SessionStorage.ParticipantPosition, {
        x,
        y,
        z,
        r,
      });
    },
    [currentParticipant, setParticipantPosition, setParticipantState],
  );

  const handleChangeParticipantProfile = useCallback(
    async (participantId: string) => {
      if (!participantId) {
        return;
      }

      const updatedParticipant = await EventsService.getParticipant(
        participantId,
      );

      if (updatedParticipant) {
        updateParticipant(participantId, updatedParticipant);
      }
    },
    [updateParticipant],
  );

  const handleMessageToWebapp = useCallback(
    (message: WebsocketMessage | undefined) => {
      if (message === HideControls) {
        setShowControls(false);
        return;
      }

      if (message === ShowControls) {
        setShowControls(true);
        return;
      }
    },
    [setShowControls],
  );

  const handleStartStreamVideoRequest = useCallback(
    ({ roomId, region, screenName }: StartStreamVideoRequestType) => {
      if (!roomId || !screenName) {
        return;
      }

      const shareMediaParams = {
        roomId,
        region,
        screenName,
      };
      setShareMediaParams(shareMediaParams);
    },
    [setShareMediaParams],
  );

  const handleMuteConference = useCallback(
    ({ conferenceId, mutedBy }: { conferenceId: string; mutedBy: string }) => {
      const currentConferenceId = DolbyService.getConferenceId();
      if (
        conferenceId === currentConferenceId &&
        currentParticipant &&
        mutedBy !== currentParticipant.id &&
        !muted
      ) {
        setMuted(true);
        showNotification(
          getSuccessNotification({
            title: translate('notifications.mutedByModerator.title'),
            message: translate(
              `notifications.mutedByModerator.${muted ? 'muted' : 'unmuted'}`,
            ),
          }),
        );
      }
    },
    [currentParticipant, muted, setMuted, showNotification],
  );

  const handleFirstPersonViewRegion = useCallback(() => {
    setDisabledPersonView(true);
  }, [setDisabledPersonView]);

  const handleFreePersonViewRegion = useCallback(() => {
    setDisabledPersonView(false);
  }, [setDisabledPersonView]);

  const handleParticipantVideoPosition = useCallback(
    (usersinscreen?: string[]) => {
      const nearbyParticipantsStr = JSON.stringify(nearbyParticipants);
      if (usersinscreen) {
        const currentNearbyParticipantIDs: string[] = usersinscreen
          .map((item: string) => {
            const { participantId }: IVideoPosition = JSON.parse(item);

            return participantId;
          })
          .sort();
        const currentNearbyParticipantIDsStr = JSON.stringify(
          currentNearbyParticipantIDs,
        );

        // conditions to skip change state if data is the same
        if (nearbyParticipantsStr === currentNearbyParticipantIDsStr) {
          return;
        }

        setNearbyParticipants(currentNearbyParticipantIDs);
      }
    },
    [nearbyParticipants, setNearbyParticipants],
  );

  const handleMutedParticipantState = useCallback(
    (participantId: string, muted: boolean) => {
      if (!muted) {
        setUnmutedParticipantsIds((prev) => [...prev, participantId]);
        return;
      }

      setUnmutedParticipantsIds((prev) =>
        prev.filter((id) => id !== participantId),
      );
    },
    [setUnmutedParticipantsIds],
  );

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    const {
      action,
      timestamp,
      gameSessionId = '',
      eventId = '',
      roomId = '',
      x,
      y,
      z,
      r,
      data,
      message,
      participantId = '',
      fileName = '',
      downloadUrl = '',
      regionSpatialType = SpatialType.SpatialAudio,
      screenName,
      mediaType = '',
      muted,
      conferenceId,
      mutedBy,
      mediaStreamId,
      usersinscreen,
      playerPosition,
      dolbySpatialAudioScale,
    } = lastJsonMessage;

    let { region = '', regionName = '' } = lastJsonMessage;

    if (region) {
      region = camelCase(region);
    }

    if (regionName) {
      regionName = camelCase(regionName);
    }

    switch (action) {
      case ConnectionInfo: {
        setWebsocketConnectionInfo(data);
        break;
      }

      case JoinedToRoom: {
        handleJoinedToRoom({ roomId, participantId, region, gameSessionId });
        break;
      }

      case LeftRoom: {
        handleLeftRoom({
          participantId,
        });
        break;
      }

      case ParticipantPosition: {
        handleParticipantPositionChanged({
          timestamp: timestamp ? parseInt(timestamp) : 0,
          gameSessionId: gameSessionId || playerPosition.CurrentGameSessionId,
          eventId: eventId || playerPosition.eventId,
          roomId: roomId || playerPosition.roomId,
          regionName,
          regionSpatialType,
          x: x || playerPosition.x,
          y: y || playerPosition.y,
          z: z || playerPosition.z,
          r: r || playerPosition.r,
        });

        break;
      }
      case ChangeRoomScale: {
        if (roomId && dolbySpatialAudioScale) {
          setRoomDolbySpatialAudioScale(roomId, dolbySpatialAudioScale);
        }

        break;
      }
      case ShowParticipantInfo: {
        handleShowParticipantInfo(participantId);
        break;
      }
      case SendToBriefcase: {
        if (!mediaType) {
          return;
        }

        handleSendToBriefcase({ fileName, downloadUrl, mediaType });
        trackPoiClick(fileName);

        break;
      }
      case ChangeParticipantProfile: {
        handleChangeParticipantProfile(participantId);
        break;
      }
      case MessageToWebapp: {
        handleMessageToWebapp(message);
        break;
      }
      case StartShareScreenRequest: {
        handleScreenVideoRequest({ roomId, region, screenName });
        break;
      }
      case StartShareVideoRequest: {
        handleShareVideoRequest({ roomId, region, screenName });
        break;
      }
      case Ue5WsConnected: {
        handleUe5WsConnected();
        break;
      }
      case Ue5WsDisconnected: {
        handleUe5WsDisconnected();
        break;
      }

      case FirstPersonViewRegion: {
        handleFirstPersonViewRegion();

        break;
      }

      case StopShareVideoRequest: {
        stopShareVideo();
        break;
      }

      case StopShareScreenRequest: {
        if (screenName && shareScreenPublishers) {
          handleStopScreenSharing(screenName);
        }
        break;
      }

      case FreePersonViewRegion: {
        handleFreePersonViewRegion();
        break;
      }

      case MuteParticipantState: {
        handleMutedParticipantState(participantId, muted as boolean);

        break;
      }

      case MuteConference: {
        if (!conferenceId || !mutedBy) {
          return;
        }

        handleMuteConference({
          conferenceId,
          mutedBy,
        });
        break;
      }

      case StopDolbyScreenSharing: {
        handleStopDolbySharing(mediaStreamId as string);
        break;
      }

      case ParticipantCameraPosition: {
        handleParticipantVideoPosition(usersinscreen);
        break;
      }
      default:
        break;
    }
  }, [
    lastJsonMessage,
    shareScreenPublishers,
    handleChangeParticipantProfile,
    handleJoinedToRoom,
    handleMessageToWebapp,
    handleSendToBriefcase,
    handleShowParticipantInfo,
    handleStartStreamVideoRequest,
    handleUe5WsConnected,
    handleUe5WsDisconnected,
    handleFirstPersonViewRegion,
    handleFreePersonViewRegion,
    setRoomDolbySpatialAudioScale,
    setWebsocketConnectionInfo,
    trackPoiClick,
    setParticipantState,
    handleMuteConference,
  ]);
};

export const useHandleEventWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const { participants, setParticipantState } = useMytaverseEvent();

  const handleJoinedToEvent = useCallback(
    async ({ participantId, eventId }: IWebSocketConnectionInfo) => {
      setParticipantState(participantId, {
        eventId,
        roomId: null,
        region: null,
      });
    },
    [participants, setParticipantState],
  );

  const handleLeftToEvent = useCallback(
    (participantId = '') => {
      if (!participantId) {
        return;
      }

      setParticipantState(participantId, {
        eventId: null,
        roomId: null,
        region: null,
      });
    },
    [setParticipantState],
  );

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    const { action, data, participantId = '' } = lastJsonMessage;

    switch (action) {
      case JoinedToEvent: {
        handleJoinedToEvent(data);
        break;
      }
      case LeftEvent: {
        handleLeftToEvent(participantId);
        break;
      }
      default:
        break;
    }
  }, [lastJsonMessage, handleJoinedToEvent, handleLeftToEvent]);
};

export const useHandleMuteParticipantWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const [showMutedByAdminNotification, setShowMutedByAdminNotification] =
    useState(false);

  const { setMuted } = useMytaverseEvent();
  const { showNotification, getSuccessNotification } = useNotification();
  const { t: translate } = useTranslation('common');

  const handleMuteParticipant = useCallback(
    (muted: boolean) => {
      if (showMutedByAdminNotification) {
        return;
      }

      setMuted(muted);
      setShowMutedByAdminNotification(true);
      showNotification(
        getSuccessNotification({
          title: translate('notifications.mutedByModerator.title'),
          message: translate(
            `notifications.mutedByModerator.${muted ? 'muted' : 'unmuted'}`,
          ),
          onClose: () => setShowMutedByAdminNotification(false),
        }),
      );
    },
    [
      getSuccessNotification,
      setMuted,
      showMutedByAdminNotification,
      showNotification,
      translate,
    ],
  );

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    const { action, data } = lastJsonMessage;

    if (action === MuteParticipant) {
      handleMuteParticipant(data?.muted || true);
    }
  }, [handleMuteParticipant, lastJsonMessage]);
};

export const useHandleBrowserUrlRequestWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const [openBrowserUrlDialog, setOpenBrowserUrlDialog] = useState(false);

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    if (lastJsonMessage.action === BrowserUrlRequest) {
      setOpenBrowserUrlDialog(true);
    }
  }, [lastJsonMessage]);

  return {
    openBrowserUrlDialog,
    setOpenBrowserUrlDialog,
  };
};

export const useHandleJoinedToRegionWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const {
    joinParticipantToRegion,
    leaveParticipantFromRegion,
    currentParticipant,
  } = useMytaverseEvent();

  useEffect(() => {
    if (!lastJsonMessage || !currentParticipant) {
      return;
    }

    const { action, timestamp, region, participantId, spatialType } =
      lastJsonMessage;

    if (!region) {
      return;
    }

    switch (action) {
      case JoinToRegion:
        if (currentParticipant.isNewParticipantPositionFlow) {
          return;
        }

        joinParticipantToRegion(
          participantId || currentParticipant.id,
          timestamp ? parseInt(timestamp, 10) : Date.now(),
          region,
          spatialType || SpatialType.SpatialAudio,
        );
        break;

      case JoinedToRegion:
        if (participantId && currentParticipant.id !== participantId) {
          joinParticipantToRegion(
            participantId,
            timestamp ? parseInt(timestamp, 10) : Date.now(),
            region,
          );
        }
        break;

      // TODO deprecated
      case LeaveRegion:
        if (currentParticipant.isNewParticipantPositionFlow) {
          return;
        }

        leaveParticipantFromRegion(
          participantId || currentParticipant.id,
          timestamp ? parseInt(timestamp, 10) : Date.now(),
          region,
        );
        break;

      case LeftRegion:
        if (participantId && currentParticipant.id !== participantId) {
          leaveParticipantFromRegion(
            participantId,
            timestamp ? parseInt(timestamp, 10) : Date.now(),
            region,
          );
        }
        break;

      default:
        break;
    }
  }, [
    lastJsonMessage,
    currentParticipant,
    joinParticipantToRegion,
    leaveParticipantFromRegion,
  ]);
};

export const useHandleFollowMeWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const {
    currentEvent,
    currentParticipant,
    setPendingFollowersData,
    setAcceptedFollowersData,
  } = useMytaverseEvent();
  const { sendMessageToEvent } = useMytaverse();
  const [play] = useSound(FollowMeSound, {
    volume: 0.15,
  });

  useEffect(() => {
    if (!lastJsonMessage || !currentEvent?.id) {
      return;
    }

    const {
      action,
      followerId = '',
      ownerId = '',
      isEventMessage = false,
    } = lastJsonMessage;

    const followerData: IFollowerData = {
      userId: followerId,
      adminId: ownerId,
    };
    const redirectedActions = [
      AcceptFollowing,
      DisengageFollowing,
      StopFollowing,
    ];

    const redirectMessageToEvent = (
      message: UnknownObjectType<string, string>,
    ) => {
      sendMessageToEvent(currentEvent?.id || '', {
        ...message,
        isEventMessage: true,
      });
    };

    const handleMessage = async () => {
      if (redirectedActions.includes(action) && !isEventMessage) {
        redirectMessageToEvent({
          action,
          followerId,
          ownerId,
        });
        return;
      }

      switch (action) {
        case FollowPlayer: {
          if (followerData.userId === currentParticipant?.userId) {
            play();
          }

          // all api updates will be done from adminId
          if (followerData.adminId === currentParticipant?.userId) {
            await EventsService.sendFollowingInvitation({
              eventId: currentEvent?.id,
              participantId: followerData.userId,
              groupLeadId: followerData.adminId,
            });
            await EventsService.acceptFollowingInvitation({
              eventId: currentEvent?.id,
              participantId: followerData.userId,
            });
          }

          setAcceptedFollowersData((prev) => prev.concat(followerData));
          break;
        }
        case InviteFollowingByAdmin: {
          if (followerData.userId === currentParticipant?.userId) {
            play();
          }

          // all api updates will be done from adminId
          if (followerData.adminId === currentParticipant?.userId) {
            await EventsService.sendFollowingInvitation({
              eventId: currentEvent?.id,
              participantId: followerData.userId,
              groupLeadId: followerData.adminId,
            });
          }

          setPendingFollowersData((prev) => prev.concat(followerData));
          break;
        }
        case StopFollowingByAdmin:
        case StopFollowing: {
          // all api updates will be done from adminId
          if (followerData.adminId === currentParticipant?.userId) {
            await EventsService.declineFollowingInvitation({
              eventId: currentEvent?.id,
              participantId: followerData.userId,
            });
          }

          setAcceptedFollowersData((prev) =>
            prev.filter(
              (data) =>
                data.userId !== followerData.userId &&
                data.adminId !== followerData.adminId,
            ),
          );
          break;
        }
        case CancelInviteFollowingByAdmin:
        case DisengageFollowing: {
          // all api updates will be done from adminId
          if (followerData.adminId === currentParticipant?.userId) {
            await EventsService.declineFollowingInvitation({
              eventId: currentEvent?.id,
              participantId: followerData.userId,
            });
          }

          setPendingFollowersData((prev) =>
            prev.filter(
              (data) =>
                data.userId !== followerData.userId &&
                data.adminId !== followerData.adminId,
            ),
          );
          break;
        }
        case AcceptFollowing: {
          // all api updates will be done from adminId
          if (followerData.adminId === currentParticipant?.userId) {
            await EventsService.acceptFollowingInvitation({
              eventId: currentEvent?.id,
              participantId: followerData.userId,
            });
          }

          setPendingFollowersData((prev) =>
            prev.filter(
              (data) =>
                data.userId !== followerData.userId &&
                data.adminId !== followerData.adminId,
            ),
          );
          setAcceptedFollowersData((prev) => prev.concat(followerData));
          break;
        }
      }
    };

    handleMessage();
  }, [
    lastJsonMessage,
    currentParticipant?.userId,
    currentEvent?.id,
    setPendingFollowersData,
    play,
    setAcceptedFollowersData,
    sendMessageToEvent,
  ]);
};

export const useHandleTeleportingModalWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const { setIsTeleportingToRoomByUnreal } = useMytaverseEvent();

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    const { action } = lastJsonMessage;

    const handleMessage = async () => {
      switch (action) {
        case OpenTeleportModal: {
          setIsTeleportingToRoomByUnreal(true);
          break;
        }
        case CloseTeleportModal: {
          setIsTeleportingToRoomByUnreal(false);
          break;
        }
      }
    };

    handleMessage();
  }, [lastJsonMessage, setIsTeleportingToRoomByUnreal]);
};
