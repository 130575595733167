import {
  IParticipant,
  IParticipantInfo,
  IParticipantRoles,
} from '../interfaces/participants';
import { SpatialType } from '../interfaces/webSocketConnectionInfo';
import { EventRole } from '../interfaces/event';

export const getInitialMapParticipants = (
  participants: IParticipant[],
  participantsInfo: IParticipantInfo[],
  participantsRoles: IParticipantRoles,
): IParticipant[] => {
  const mapParticipants = participants.map((participant) => {
    const participantRoom = participantsInfo.find(
      (participantInfo) => participantInfo.participantId === participant.id,
    );

    if (participantRoom) {
      participant.roomId = participantRoom.roomId;
      participant.region = participantRoom.region
        ? {
            region: participantRoom.region,
            timestamp: Date.now(),
            state: 'joined',
            regionSpatialType: SpatialType.SpatialAudio,
          }
        : null;
      participant.gameSessionId = participantRoom.gameSessionId || null;
    }

    participant.role = getParticipantRole(
      participant.userId,
      participantsRoles,
    );

    return participant;
  });

  return mapParticipants;
};

const getParticipantRole = (
  participantUserId: string,
  participantsRoles: IParticipantRoles,
): EventRole => {
  const isAdmin = participantsRoles.admin.includes(participantUserId);

  if (isAdmin) return EventRole.Admin;

  const isModerator = participantsRoles.moderator.includes(participantUserId);

  if (isModerator) return EventRole.Moderator;

  const isCoordinator =
    participantsRoles.coordinator.includes(participantUserId);

  if (isCoordinator) return EventRole.Coordinator;

  return EventRole.None;
};
