import { findLast, isEqual } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useAsyncEffect from 'use-async-effect/';

import { IAvatarSkin } from '../../../../../interfaces/avatarSkin';
import { IMillicastPublisher } from '../../../../../interfaces/millicastPublisher';
import {
  IMillicastEventTokens,
  IMillicastStreamTokens,
  IMillicastTokens,
} from '../../../../../interfaces/millicastTokens';
import { IShareMediaParams } from '../../../../../interfaces/shareMediaParams';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import EventsService, {
  IEventParticipantData,
} from '../../../../../services/EventsService';
import ParticipantService from '../../../../../services/ParticipantsService';
import MillicastService from '../../../components/HomeBottomBar/ShareScreen/helpers';
import {
  EventParticipantDataType,
  InitMessageActions,
  JoinParticipantToRegionType,
  LeaveParticipantFromRegionType,
  MillicastPropsType,
  NewRegionsType,
  ParticipantsStateHookProps,
  SetParticipantStateType,
  UseInitMessageProps,
} from '../interfaces';
import { getInitialMapParticipants } from '../../../../../helpers/participant';
import {
  IParticipant,
  IParticipantRegion,
} from '../../../../../interfaces/participants';
import {
  SpatialType,
  WebsocketAction,
} from '../../../../../interfaces/webSocketConnectionInfo';
import { IInitMessage } from '../../../components/DashboardContent/Pureweb/interfaces';
import { ParticipantPosition } from '../../../components/DashboardContent/interfaces';
import { getSessionStorageValue } from '../../../../../helpers/sessionStorage';
import { isMobile } from 'react-device-detect';
import { SessionStorage } from '../../../../../constants/storage';
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../components/Notification';
import { useTranslation } from 'react-i18next';

export const useMillicast = ({
  currentRoom,
  currentRegion,
  currentParticipant,
  muted,
  setMuted,
}: MillicastPropsType) => {
  const { t: translate } = useTranslation('common');
  const { showNotification, getMillicastNotification } = useNotification();

  const [millicastTokens, setMillicastTokens] =
    React.useState<IMillicastEventTokens | null>(null);

  const [shareMillicastVideoWithSound, setShareMillicastVideoWithSound] =
    useState(true);

  const [openScreenModal, setOpenScreenModal] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const prevMutedState = useRef<boolean | null>(null);
  const checkPrevMutedState = useRef(false);

  const [loadingShareScreenModal, setLoadingShareScreenModal] = useState(false);
  const [loadingShareVideoModal, setLoadingShareVideoModal] = useState(false);

  const [shareMediaParams, setShareMediaParams] =
    useState<IShareMediaParams | null>(null);

  const [shareVideoPublishers, setShareVideoPublishers] =
    useState<IMillicastPublisher | null>(null);
  const [shareScreenPublishers, setShareScreenPublishers] = useState<
    IMillicastPublisher[]
  >([]);
  const [shareVideoMediaStream, setShareVideoMediaStream] =
    useState<MediaStream | null>(null);
  const [shareScreenMediaStreams, setShareScreenMediaStreams] = useState<
    MediaStream[]
  >([]);

  const [micUsingByMillicast, setMicUsingByMillicast] = useState(false);

  const getMillicastStreamTokens = useCallback(
    async (streamName: string): Promise<IMillicastStreamTokens | null> => {
      if (millicastTokens) {
        const streamExistsInPublishToken =
          millicastTokens.publishToken.streams.some((sn) => sn === streamName);
        const streamExistsInSubscribeToken =
          millicastTokens.subscribeToken.streams.some(
            (sn) => sn === streamName,
          );

        if (!streamExistsInPublishToken) {
          await MillicastService.updatePublishToken(
            millicastTokens.publishToken.id,
            streamName,
          );
        }

        if (!streamExistsInSubscribeToken) {
          await MillicastService.updateSubscribeToken(
            millicastTokens.subscribeToken.id,
            streamName,
          );
        }

        if (!streamExistsInPublishToken || !streamExistsInSubscribeToken) {
          setMillicastTokens((prev) => {
            if (!prev) {
              return prev;
            }

            let publishToken = prev.publishToken;
            let subscribeToken = prev.subscribeToken;

            if (!streamExistsInPublishToken) {
              publishToken = {
                ...publishToken,
                streams: [...publishToken.streams, streamName],
              };
            }

            if (!streamExistsInSubscribeToken) {
              subscribeToken = {
                ...subscribeToken,
                streams: [...subscribeToken.streams, streamName],
              };
            }

            return {
              ...prev,
              publishToken,
              subscribeToken,
            };
          });
        }

        return {
          streamName,
          accountId: millicastTokens.accountId,
          publishingToken: millicastTokens.publishToken.token,
          subscribingToken: millicastTokens.subscribeToken.token,
        };
      }

      return null;
    },
    [millicastTokens],
  );

  const startShareVideoHandler = useCallback(
    async (
      shareVideoPublishers: IMillicastPublisher,
      shareVideoTokens: IMillicastTokens,
    ) => {
      if (!currentRoom || !shareMediaParams) {
        return;
      }

      const data = {
        roomId: currentRoom.id,
        region: currentRegion?.region,
        spotId: shareMediaParams.screenName,
        accountId: shareVideoTokens.accountId,
        ueIdentifier: shareMediaParams.screenName,
        streamName: shareVideoPublishers.streamName,
        subscribingToken: shareVideoTokens.subscribingToken,
        participantId: currentParticipant ? currentParticipant.userId : null,
        isScreen: false,
        isCamera: true,
        nameOwner: currentParticipant.fullName,
        target: 'UE5',
      };

      await EventsService.startStreamVideo(data);
      setShareVideoPublishers(shareVideoPublishers);

      // eslint-disable-next-line no-console
      console.log(`Start broadcast video: ${JSON.stringify(data)}`);
    },
    [
      setShareVideoPublishers,
      currentRoom,
      shareMediaParams,
      currentRegion,
      currentParticipant,
    ],
  );

  const stopShareVideoHandler = useCallback(async () => {
    if (!shareVideoPublishers) {
      return;
    }

    await MillicastService.stopStream(shareVideoPublishers);
    setShareVideoPublishers(null);

    // eslint-disable-next-line no-console
    console.log(
      `Stop broadcast video: streamName=${shareVideoPublishers.streamName}`,
    );
  }, [shareVideoPublishers, setShareVideoPublishers]);

  const startShareScreenHandler = useCallback(
    async (
      shareScreenPublishers: IMillicastPublisher,
      shareScreenTokens: IMillicastTokens,
    ) => {
      if (!currentRoom || !shareMediaParams) {
        return;
      }

      const data = {
        roomId: currentRoom.id,
        region: currentRegion?.region,
        spotId: shareMediaParams.screenName,
        accountId: shareScreenTokens.accountId,
        ueIdentifier: shareMediaParams.screenName,
        streamName: shareScreenPublishers.streamName,
        subscribingToken: shareScreenTokens.subscribingToken,
        participantId: currentParticipant ? currentParticipant.userId : null,
        isScreen: true,
        isCamera: false,
        nameOwner: currentParticipant.fullName,
        target: 'ALL',
      };

      await EventsService.startStreamVideo(data);
      setShareScreenPublishers((prev) => [...prev, shareScreenPublishers]);

      // eslint-disable-next-line no-console
      console.log(`Start broadcast screen: ${JSON.stringify(data)}`);
    },
    [
      setShareScreenPublishers,
      currentRoom,
      currentRegion,
      shareMediaParams,
      currentParticipant,
    ],
  );

  const stopShareScreenHandler = useCallback(
    async (streamId: string) => {
      const currentPublisher = shareScreenPublishers.find(
        (p) => p.options.mediaStream.id === streamId,
      );

      if (!currentPublisher) {
        return;
      }

      await MillicastService.stopStream(currentPublisher);

      setShareScreenPublishers((prev) =>
        prev.filter((p) => p.options.mediaStream.id !== streamId),
      );
      // eslint-disable-next-line no-console
      console.log(
        `Stop broadcast screen: streamName=${currentPublisher.streamName}`,
      );
    },
    [shareScreenPublishers, setShareScreenPublishers],
  );

  useAsyncEffect(async () => {
    if (shareVideoMediaStream) {
      shareVideoMediaStream.getVideoTracks().forEach((t) => {
        t.onended = async () => {
          setShareVideoMediaStream(null);
          setShareVideoPublishers(null);
          setOpenVideoModal(false);
        };
      });
    }

    if (shareVideoPublishers) {
      shareVideoPublishers.options.mediaStream.getVideoTracks().forEach((t) => {
        t.onended = async () => {
          if (shareVideoPublishers) {
            showNotification(
              getMillicastNotification({
                type: NOTIFICATION_TYPES.ERROR,
                message: translate(
                  'notifications.millicast.videoSharingStopped',
                ),
              }),
            );

            await stopShareVideoHandler();
          }

          setShareVideoMediaStream(null);
          setShareVideoPublishers(null);
          setOpenVideoModal(false);
        };
      });
    } else if (!openVideoModal && shareVideoMediaStream) {
      await MillicastService.stopMediaStreamTracks(shareVideoMediaStream);
      setShareVideoMediaStream(null);
      setShareVideoPublishers(null);
      setOpenVideoModal(false);
    }
  }, [
    shareVideoPublishers,
    shareVideoMediaStream,
    stopShareVideoHandler,
    openVideoModal,
  ]);

  useEffect(() => {
    if (shareScreenMediaStreams?.length) {
      shareScreenMediaStreams.forEach(
        (s) =>
          (s.getVideoTracks()[0].onended = async () => {
            if (shareScreenPublishers) {
              showNotification(
                getMillicastNotification({
                  type: NOTIFICATION_TYPES.ERROR,
                  message: translate(
                    'notifications.millicast.videoSharingStopped',
                  ),
                }),
              );

              await handleStopScreenStream(s.id);
              setOpenScreenModal(false);
            }

            setShareScreenMediaStreams((prev) =>
              prev.filter((streams) => streams.id !== s.id),
            );

            MillicastService.stopMediaStreamTracks(s);
          }),
      );
    }
  }, [shareScreenMediaStreams, shareScreenPublishers]);

  useEffect(() => {
    if (!openScreenModal) {
      const publishedStreamIds = shareScreenPublishers.map(
        (stream) => stream.options.mediaStream.id,
      );

      setShareScreenMediaStreams((prev) => {
        return prev.filter(async (s) => {
          const isStreamPublished = publishedStreamIds.find(
            (id) => id === s.id,
          );

          if (!isStreamPublished) {
            MillicastService.stopMediaStreamTracks(s);

            return false;
          }

          return true;
        });
      });
    }
  }, [openScreenModal, shareScreenPublishers]);

  useAsyncEffect(async () => {
    if (
      shareVideoPublishers &&
      currentRegion?.region === shareVideoPublishers.region &&
      shareMillicastVideoWithSound
    ) {
      if (!muted) {
        prevMutedState.current = false;
        checkPrevMutedState.current = true;
        setMuted(true);
      }
      setMicUsingByMillicast(true);

      return;
    }

    if (muted !== prevMutedState.current && checkPrevMutedState.current) {
      setMuted(prevMutedState.current !== null ? prevMutedState.current : true);
      checkPrevMutedState.current = false;
    }

    setMicUsingByMillicast(false);
  }, [shareMillicastVideoWithSound, shareVideoPublishers, currentRegion]);

  const initScreenMediaStream = useCallback(async () => {
    const mediaStream = await navigator.mediaDevices
      .getDisplayMedia({
        video: { frameRate: { max: 30, ideal: 30 } },
        audio: true,
      })
      .catch((e) => {
        console.log(e);
        setOpenScreenModal(false);
        return;
      });

    if (!mediaStream) {
      return;
    }

    if (!shareScreenPublishers) {
      return;
    }

    const publishedIds = shareScreenPublishers.map(
      (stream) => stream.options.mediaStream.id,
    );

    setShareScreenMediaStreams((prev) => {
      const publishedStreams = prev.filter((s) => {
        const isStreamPublished = publishedIds.find((id) => id === s.id);

        if (!isStreamPublished) {
          handleStopScreenStream(s.id);
          return false;
        }

        return true;
      });

      return [...publishedStreams, mediaStream];
    });
  }, [
    shareScreenMediaStreams,
    setShareScreenMediaStreams,
    shareScreenPublishers,
  ]);

  const handleStopScreenStream = useCallback(
    async (streamId: string) => {
      const currentPublisher = shareScreenPublishers?.find(
        (p) => p.options.mediaStream.id === streamId,
      );

      if (currentPublisher) {
        await MillicastService.stopStream(currentPublisher);
      }

      if (shareScreenMediaStreams?.length) {
        const currentMediaStream = shareScreenMediaStreams.find(
          (s) => s.id === streamId,
        );

        if (!currentMediaStream) {
          return;
        }

        MillicastService.stopMediaStreamTracks(currentMediaStream);
      }

      setShareScreenMediaStreams((prev) =>
        prev.filter((s) => s.id !== streamId),
      );

      await stopShareScreenHandler(streamId);
    },
    [
      shareScreenPublishers,
      shareScreenMediaStreams,
      stopShareScreenHandler,
      initScreenMediaStream,
    ],
  );

  return {
    millicastTokens,
    setMillicastTokens,
    getMillicastStreamTokens,
    shareMillicastVideoWithSound,
    shareMediaParams,
    shareVideoPublishers,
    shareScreenPublishers,
    shareVideoMediaStream,
    shareScreenMediaStreams,
    openScreenModal,
    openVideoModal,
    micUsingByMillicast,
    setMicUsingByMillicast,
    setOpenVideoModal,
    setOpenScreenModal,
    initScreenMediaStream,
    handleStopScreenStream,
    stopShareScreenHandler,
    startShareScreenHandler,
    stopShareVideoHandler,
    startShareVideoHandler,
    setShareScreenMediaStreams,
    setShareVideoMediaStream,
    setShareVideoPublishers,
    setShareScreenPublishers,
    setShareMediaParams,
    setShareMillicastVideoWithSound,

    loadingShareVideoModal,
    setLoadingShareVideoModal,

    loadingShareScreenModal,
    setLoadingShareScreenModal,
  };
};

export const useEventParticipantData = ({
  currentEvent,
  participant,
  setLoading,
}: EventParticipantDataType) => {
  const { selectLanguage, selectedLanguage, userId } = useMytaverse();
  const firstRenderSettings = useRef<IEventParticipantData | null>(null);
  const settingsRequestTimeout = useRef<NodeJS.Timeout | null>(null);

  const [showActiveCameras, setShowActiveCameras] = useState(true);
  const [participantsSound, setParticipantsSound] = useState(1);
  const [gameSound, setGameSound] = useState(0.5);

  const [customAvatarUrl, setCustomAvatarUrl] = useState<string | null>(null);
  const [currentAvatarId, setCurrentAvatarId] = useState<string | null>(null);
  const [currentSkin, setCurrentSkin] = useState<IAvatarSkin | null>(null);

  useAsyncEffect(async () => {
    if (firstRenderSettings.current || !currentEvent || !participant) return;

    const settings = await EventsService.getEventUserData(
      currentEvent.id,
      participant.id,
    );

    if (settings) {
      const {
        selectedLanguage,
        participantsSound,
        gameSound,
        showActiveCameras,
      } = settings;

      selectLanguage((selectedLanguage as string) || 'EN');
      setParticipantsSound((participantsSound as number) || 1);
      setGameSound((gameSound as number) || 0.5);
      setShowActiveCameras(showActiveCameras as boolean);

      firstRenderSettings.current = {
        eventId: currentEvent.id,
        participantId: participant.id,
        selectedLanguage,
        participantsSound,
        gameSound,
        showActiveCameras,
      };
    }
  }, [participant, currentEvent]);

  useAsyncEffect(async () => {
    //TODO create request to set setting
    if (!participant || !currentEvent) return;

    if (!firstRenderSettings.current) {
      firstRenderSettings.current = {
        eventId: currentEvent.id,
        participantId: participant.id,
        selectedLanguage,
        participantsSound,
        gameSound,
        showActiveCameras,
      };
    }

    const isEqualSettings = isEqual(firstRenderSettings.current, {
      eventId: currentEvent.id,
      participantId: participant.id,
      selectedLanguage,
      participantsSound,
      gameSound,
      showActiveCameras,
    });

    if (!isEqualSettings) {
      if (settingsRequestTimeout.current) {
        clearTimeout(settingsRequestTimeout.current);
        settingsRequestTimeout.current = null;
      }

      settingsRequestTimeout.current = setTimeout(async () => {
        firstRenderSettings.current = {
          eventId: currentEvent.id,
          participantId: participant.id,
          selectedLanguage,
          participantsSound,
          gameSound,
          showActiveCameras,
        };

        await EventsService.setEventUserData(currentEvent.id, participant.id, {
          selectedLanguage,
          participantsSound,
          gameSound,
          showActiveCameras,
        });

        settingsRequestTimeout.current = null;
      }, 1000);
    }
  }, [
    selectedLanguage,
    participantsSound,
    gameSound,
    participant,
    currentEvent,
    showActiveCameras,
  ]);

  const setParticipantsSoundHandle = useCallback(
    (level: any) => {
      localStorage.setItem('participantsSound', String(level));
      setParticipantsSound(level);
    },
    [setParticipantsSound],
  );

  const setGameSoundHandle = useCallback(
    (level: number) => {
      localStorage.setItem('worldSoundLevel', String(level));
      setGameSound(level);
    },
    [setGameSound],
  );

  const selectSkin = useCallback(
    async (skin: IAvatarSkin) => {
      setLoading(true);
      if (!currentEvent || !participant) return;
      try {
        await EventsService.setEventSkin({
          avatar: skin.idAvatar,
          event: currentEvent.id || '',
          player: participant.email,
          skin: skin.idSkinModel,
        });
        setCustomAvatarUrl(null);
        setCurrentSkin(skin);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    },
    [setLoading, setCustomAvatarUrl, setCurrentSkin],
  );

  const selectSkinHandler = useCallback(
    async (skin: IAvatarSkin) => {
      if (!currentEvent || !userId) {
        return;
      }

      await EventsService.setEventUserData(currentEvent.id, userId, {
        skinId: skin.idSkinModel,
        avatarId: skin.avatar,
        customAvatarUrl: '',
      });

      selectSkin(skin);
    },
    [selectSkin, participant, currentEvent],
  );

  return {
    showActiveCameras,
    participantsSound,
    gameSound,
    customAvatarUrl,
    currentAvatarId,
    currentSkin,
    setCurrentSkin,
    setCurrentAvatarId,
    setCustomAvatarUrl,
    setGameSound,
    setParticipantsSound,
    setShowActiveCameras,
    setGameSoundHandle,
    setParticipantsSoundHandle,
    selectSkinHandler,
  };
};

export const useParticipantsState = ({
  userId,
  currentEventId,
  currentParticipant,
  setParticipants,
  setCurrentParticipant,
  currentRoom,
  setCurrentRoom,
  currentRegion,
  setCurrentRegion,
  rooms,
}: ParticipantsStateHookProps) => {
  const { sendJSONMessageToWebSocket } = useMytaverse();

  const syncEventParticipantsState = useCallback(async () => {
    if (!currentEventId) {
      return;
    }

    const participantsInfo = await EventsService.getEventParticipantsInfo(
      currentEventId,
    );
    const participantRoles = await EventsService.getEventParticipantsRoles(
      currentEventId,
    );

    setParticipants((participants) =>
      getInitialMapParticipants(
        participants,
        participantsInfo,
        participantRoles,
      ),
    );
    setCurrentParticipant((prev) => {
      if (!prev) {
        return;
      }

      const participantInfo = participantsInfo.find(
        (participantInfo) => participantInfo.participantId === userId,
      );

      if (participantInfo && participantInfo.roomId !== prev?.roomId) {
        return {
          ...prev,
          roomId: participantInfo.roomId,
        };
      }

      return prev;
    });
  }, [currentEventId, userId]);

  // Set current room
  useAsyncEffect(async () => {
    if (!currentParticipant) {
      return;
    }

    const webSocketMessages = [];

    if (
      currentParticipant.roomId &&
      (!currentRoom ||
        currentRoom.id !== currentParticipant.roomId ||
        currentRoom.gameSessionId !== currentParticipant.gameSessionId)
    ) {
      const room = rooms.find(({ id }) => id === currentParticipant.roomId);

      if (!room) {
        return;
      }

      setCurrentRoom({
        ...room,
        gameSessionId: currentParticipant.gameSessionId,
      });

      webSocketMessages.push({
        action: WebsocketAction.JoinedToRoom,
        timestamp: Date.now(),
        participantId: currentParticipant.id,
        eventId: currentParticipant.eventId,
        roomId: room.id,
        regionName: currentParticipant.region
          ? currentParticipant.region.region
          : null,
        gameSessionId: currentParticipant.gameSessionId,
        isSpeaker: currentParticipant.isSpeaker,
      });
    } else if (!currentParticipant.roomId && currentRoom) {
      setCurrentRoom(null);
      setCurrentRegion(null);
    }

    const currentRegionName = currentRegion ? currentRegion.region : null;
    const currentParticipantRegionName = currentParticipant.region
      ? currentParticipant.region.region
      : null;

    if (currentRegionName !== currentParticipantRegionName) {
      setCurrentRegion(currentParticipant.region);

      if (currentParticipant.region) {
        webSocketMessages.push({
          action: WebsocketAction.JoinedToRegion,
          timestamp: Date.now(),
          participantId: currentParticipant.id,
          region: currentParticipant.region.region,
        });

        // eslint-disable-next-line no-console
        console.log(
          `${currentParticipant.fullName} joined to ${currentParticipant.region?.region} region`,
        );
      }

      if (currentRegionName && !currentParticipantRegionName) {
        webSocketMessages.push({
          action: WebsocketAction.LeftRegion,
          timestamp: Date.now(),
          participantId: currentParticipant.id,
          region: currentRegion?.region,
        });

        // eslint-disable-next-line no-console
        console.log(
          `${currentParticipant.fullName} left ${currentRegionName} region`,
        );
      }
    }

    if (webSocketMessages.length !== 0) {
      await Promise.all(
        webSocketMessages.map((webSocketMessage) =>
          sendJSONMessageToWebSocket(webSocketMessage),
        ),
      );
    }
  }, [currentParticipant, currentRoom, currentRegion]);

  // Sync participants state
  useEffect(() => {
    const interval = setInterval(async () => {
      await syncEventParticipantsState();
    }, 30000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [syncEventParticipantsState]);

  const setParticipantState: SetParticipantStateType = useCallback(
    (participantId, participantState) => {
      if (userId === participantId) {
        setCurrentParticipant((prev) => {
          if (!prev) {
            return prev;
          }

          return {
            ...prev,
            ...participantState,
          };
        });
      }

      setParticipants((prev) => {
        const isNewParticipant = !prev.some(
          ({ userId }) => userId === participantId,
        );

        if (isNewParticipant) {
          return [
            ...prev,
            {
              ...ParticipantService.getEmptyParticipant(participantId),
              ...participantState,
            } as IParticipant,
          ];
        }

        return prev.map((participant) => {
          if (participant.id === participantId) {
            return {
              ...participant,
              ...participantState,
            };
          }

          return participant;
        });
      });
    },
    [userId],
  );

  const getNewRegionsOnJoinToRegion = (
    regions: IParticipantRegion[],
    region: string,
    timestamp: number,
    regionSpatialType?: SpatialType,
  ): NewRegionsType => {
    let newRegions;

    if (regions.find((r) => r.region === region && r.state === 'leaving')) {
      let filteredLeaving = false;

      newRegions = regions.filter((r) => {
        if (!filteredLeaving && r.region === region && r.state === 'leaving') {
          filteredLeaving = true;
          return false;
        }

        return true;
      });
    } else {
      newRegions = [
        ...regions,
        { timestamp, region, state: 'joined', regionSpatialType },
      ];
    }

    newRegions.sort((a, b) => a.timestamp - b.timestamp);
    const newRegion = findLast(newRegions, (i) => i.state === 'joined');

    return {
      newRegions,
      newRegion: newRegion || null,
    };
  };

  const joinParticipantToRegion: JoinParticipantToRegionType = useCallback(
    (participantId, timestamp, region, regionSpatialType) => {
      if (userId === participantId) {
        setCurrentParticipant((prev) => {
          if (!prev) {
            return prev;
          }

          const { newRegion, newRegions } = getNewRegionsOnJoinToRegion(
            prev.regions || [],
            region,
            timestamp,
            regionSpatialType,
          );

          return {
            ...prev,
            region: newRegion,
            regions: newRegions,
          };
        });
      }

      setParticipants((prev) =>
        prev.map((participant) => {
          if (participant.id === participantId) {
            const { newRegion, newRegions } = getNewRegionsOnJoinToRegion(
              participant.regions || [],
              region,
              timestamp,
              regionSpatialType,
            );

            if (newRegion) {
              // eslint-disable-next-line no-console
              console.log(
                `${participant.fullName} joined to ${newRegion.region} region`,
              );
            }

            return {
              ...participant,
              region: newRegion,
              regions: newRegions,
            };
          }

          return participant;
        }),
      );
    },
    [userId],
  );

  const getNewRegionsOnLeaveRegion = (
    regions: IParticipantRegion[],
    region: string,
    timestamp: number,
  ): NewRegionsType => {
    let foundRegion = false;

    let newRegions = regions.map((i) => {
      if (!foundRegion && i.region === region && i.state === 'joined') {
        foundRegion = true;

        return {
          ...i,
          state: 'left',
        };
      }

      return i;
    });

    if (!foundRegion) {
      newRegions = [...regions, { timestamp, region, state: 'leaving' }];
      newRegions.sort((a, b) => a.timestamp - b.timestamp);
    }

    const newRegion = findLast(newRegions, (i) => i.state === 'joined');

    return {
      newRegions: newRegions.filter((r) => r.state !== 'left'),
      newRegion: newRegion || null,
    };
  };

  const leaveParticipantFromRegion: LeaveParticipantFromRegionType =
    useCallback(
      (participantId, timestamp, region) => {
        if (userId === participantId) {
          setCurrentParticipant((prev) => {
            if (!prev) {
              return prev;
            }

            const { newRegions, newRegion } = getNewRegionsOnLeaveRegion(
              prev.regions || [],
              region,
              timestamp,
            );

            return {
              ...prev,
              region: newRegion,
              regions: newRegions,
            };
          });
        }

        setParticipants((prev) =>
          prev.map((participant) => {
            if (participant.id === participantId) {
              const { newRegions, newRegion } = getNewRegionsOnLeaveRegion(
                participant.regions || [],
                region,
                timestamp,
              );

              if (participant.region?.region && !newRegion) {
                // eslint-disable-next-line no-console
                console.log(
                  `${participant.fullName} left ${participant.region.region} region`,
                );
              }

              return {
                ...participant,
                region: newRegion,
                regions: newRegions,
              };
            }

            return participant;
          }),
        );
      },
      [userId],
    );

  return {
    setParticipantState,
    joinParticipantToRegion,
    leaveParticipantFromRegion,
  };
};

export const useParticipants = () => {
  //todo move another participant logic
  const [unmutedParticipantsIds, setUnmutedParticipantsIds] = useState<
    string[]
  >([]);

  return {
    unmutedParticipantsIds,
    setUnmutedParticipantsIds,
  };
};

export const useInitMessage = ({
  token,
  websocketSessionId,
  currentParticipant,
  currentSkin,
  dolbyToken,
  currentEvent,
  customAvatarUrl,
  teleportingToRoom,
}: UseInitMessageProps) => {
  const webSocketUrl = new URL(
    process.env.REACT_APP_SPATIAL_MANAGER_WEB_SOCKET_URL as string,
  );

  webSocketUrl.searchParams.append('token', token as string);
  webSocketUrl.searchParams.append('clientType', 'UE5');
  webSocketUrl.searchParams.append('session', websocketSessionId);

  const storageParticipantPosition =
    getSessionStorageValue<ParticipantPosition>(
      SessionStorage.ParticipantPosition,
    );
  const defaultParticipantPosition = {
    x: 0,
    y: 0,
    z: 0,
    r: 0,
  };

  const initMessageHandler = useCallback((): IInitMessage | null => {
    if (
      !token ||
      !currentParticipant ||
      !teleportingToRoom ||
      !currentEvent ||
      !(currentSkin || customAvatarUrl)
    ) {
      return null;
    }
    const message: IInitMessage = {
      action: InitMessageActions.INIT,
      token,
      webSocketUrl,
      participantId: currentParticipant.id,
      changePositionInterval: 1,
      eventId: currentEvent.id,
      avatarId: currentSkin ? currentSkin.avatar : null,
      skinId: currentSkin ? currentSkin.idSkinModel || currentSkin.id : null,
      roomId: teleportingToRoom.id,
      screenHeight: window.innerHeight >= 1080 ? 1080 : window.innerHeight,
      screenWidth: window.innerWidth >= 1980 ? 1980 : window.innerWidth,
      downloadAvatarUrl: customAvatarUrl || '',
      isMobile,
      dolbyToken,
      isSpeaker: currentParticipant.isSpeaker,
      useLastParticipantPosition: !!storageParticipantPosition,
      lastParticipantPosition:
        storageParticipantPosition ?? defaultParticipantPosition,
    };

    return message;
  }, [
    token,
    websocketSessionId,
    currentParticipant,
    currentSkin,
    dolbyToken,
    currentEvent,
    customAvatarUrl,
    teleportingToRoom,
    isMobile,
    customAvatarUrl,
  ]);

  return {
    initMessageHandler,
  };
};
